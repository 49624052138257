<template>
  <MasterDetail
    formTitle="Cadastro LALUR"
    :cols="cols"
    :resourceUrl="resourceUrl"
    :descriptionProperty="item => `${item.ano} - ${periodos[item.periodo]}`"
    :opts="opts"
    has-year-filter
  ></MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
export default {
  components: {
    MasterDetail,
  },
  computed: {
    client: function () {
      return this.getClient();
    },
    resourceUrl: function () {
      return `/v1/clientes/${this.client.clientId}/lalur`;
    },
    periodos: function () {
      return this.opts.periodos.reduce((a, b) => {
        a[b.value] = b.name;
        return a;
      }, {});
    },
  },
  created: function () {
    var year = new Date().getFullYear();
    this.opts.anos = [{ value: year }, { value: year - 1 }];
  },
  data: function () {
    return {
      cols: [
        { key: "id", name: "ID", hideInform: true },
        {
          key: "ano",
          name: "Ano",
          type: this.$fieldTypes.SELECT,
          defaultValue: new Date().getFullYear(),
          rules: [{ rule: "required" }],
          rel: { to: "anos", key: "value", name: "value" },
        },
        {
          key: "periodo",
          name: "Período",
          type: this.$fieldTypes.SELECT,
          rules: [{ rule: "required" }],
          rel: { to: "periodos", key: "value", name: "name" },
        },
        {
          key: "valor",
          name: "Valor",
          align: 1,
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          rules: [{ rule: "required" }],
        },
      ],
      opts: {
        anos: [],
        periodos: [
          { value: 1, name: "1º Trimestre" },
          { value: 2, name: "2º Trimestre" },
          { value: 3, name: "3º Trimestre" },
          { value: 4, name: "4º Trimestre" },
          { value: 12, name: "Anual" },
        ],
      },
    };
  },
};
</script>